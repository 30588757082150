import React from "react"

import PaddingWrapper from "./PaddingWrapper"
import { SectionHeading } from "../../../UI/Common"

const SectionHeadingContent = ({ heading, config }) => (
  <PaddingWrapper padding={config?.padding}>
    <SectionHeading text={heading} />
  </PaddingWrapper>
)

export default SectionHeadingContent
